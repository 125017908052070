var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"formRef",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"240px","label-suffix":"："}},[_c('el-form-item',{attrs:{"label":"企业注册名称","prop":"requiredForm"}},[_c('span',{class:{ highLight: _vm.detailData.companyNameLight }},[_vm._v(" "+_vm._s(_vm.detailData.companyName)+" ")])]),_c('el-form-item',{attrs:{"label":"企业证件号码","prop":"requiredForm"}},[_c('span',{class:{ highLight: _vm.detailData.companyCardLight }},[_vm._v(" "+_vm._s(_vm.detailData.companyCard)+" ")])]),_c('el-form-item',{attrs:{"label":"公司所在国家","prop":"requiredForm"}},[_c('span',{class:{ highLight: _vm.detailData.countryNameLight }},[_vm._v(" "+_vm._s(_vm.detailData.countryName)+" ")])]),_c('el-form-item',{attrs:{"label":"企业证件","prop":"requiredForm"}},[(
        _vm.detailData.identityAdjunctVo &&
        _vm.detailData.identityAdjunctVo.companyMergeFile &&
        _vm.pdfType(_vm.detailData.identityAdjunctVo.companyMergeFile)
      )?_c('span',{staticClass:"textBtn",class:{
        highLight: _vm.detailData.identityAdjunctVo
          ? _vm.detailData.identityAdjunctVo.companyMergeFileLight
          : '',
      },on:{"click":function($event){return _vm.gourl(_vm.detailData.identityAdjunctVo.companyMergeFile)}}},[_vm._v(_vm._s(_vm.detailData.identityAdjunctVo.companyMergeFile))]):_vm._e(),(!_vm.pdfType(_vm.detailData.identityAdjunctVo.companyMergeFile))?_c('div',{class:{
        width24: true,
        highLight: _vm.detailData.identityAdjunctVo
          ? _vm.detailData.identityAdjunctVo.companyMergeFileLight
          : '',
      }},[(
          !_vm.detailData.identityAdjunctVo ||
          (!_vm.detailData.identityAdjunctVo.personHand &&
            !_vm.detailData.identityAdjunctVo.companyMergeFile)
        )?_c('div',{staticClass:"mr20 disfr jc ac",staticStyle:{"width":"100px","height":"100px","border":"1px solid #999"}},[_vm._v(" 暂无图片 ")]):_vm._e(),(
          _vm.detailData.identityAdjunctVo &&
          (_vm.detailData.identityAdjunctVo.personHand ||
            _vm.detailData.identityAdjunctVo.companyMergeFile)
        )?_c('el-image',{staticClass:"mr20",staticStyle:{"width":"150px"},attrs:{"src":_vm.detailData.identityAdjunctVo.personHand ||
          _vm.detailData.identityAdjunctVo.companyMergeFile,"preview-src-list":[
          _vm.detailData.identityAdjunctVo.personHand ||
            _vm.detailData.identityAdjunctVo.companyMergeFile,
        ]}}):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }